<template>
  <div class="page-contain my-table-list">
    <breadcrumb :breadStaticData="breadStaticData" :linkData="linkData" :thirdStaticData="thirdStaticData"></breadcrumb>
    <div class="page-table">
      <div class="title-search">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline">
          <div class="title-search-line">
            <el-form-item label="投入品类别:">
              <el-select size="small" v-model="searchForm.type" placeholder="请选择投入品类别" clearable>
                <el-option v-for="(item, index) in inputsType" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="投入品名称:">
              <el-input size="small" v-model="searchForm.inputsName" placeholder="请输入投入品名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="销售商:">
              <el-input size="small" v-model="searchForm.storeName" placeholder="请输入销售商" clearable></el-input>
            </el-form-item>
            <el-form-item label="收购日期:">
              <el-date-picker
                class="my-date-range"
                v-model="timeRange"
                @change="timeChange"
                type="daterange"
                align="right"
                unlink-panels
                size="small"
                range-separator="到"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-button type="primary" size="small" @click="getData(true)">查询</el-button>
          </div>
        </el-form>
      </div>
      <div class="title-tab">
        <div class="title-tab-btns">
          <el-button type="primary" size="small" icon="el-icon-plus" @click="add">新增</el-button>
          <el-button type="primary" size="small" icon="el-icon-download" @click="download">导出</el-button>
        </div>
      </div>
      <el-table 
        ref="table" 
        class="my-table"
        size="small" 
        :data="listData" 
        highlight-current-row 
        border
        v-loading="loading"  
        :element-loading-text="loadtext" 
        :height="tableHeight"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <el-table-column align="center" type="index" width="70" label="序号">
          <template slot-scope="scope">
            <span>{{
              pageparm
                ? (pageparm.page - 1) * pageparm.limit +
                  scope.$index +
                  1
                : scope.$index + 1
            }}</span>
          </template>
        </el-table-column>
        
        <el-table-column
          v-for="(headData, index) in tableHead"
          :key="index"
          :prop="headData.prop"
          :label="headData.label"
          :width="headData.width"
          :align="headData.align || 'center'"
          :header-align="headData.headerAlign || 'center'"
          :min-width="headData.minWidth"
          :show-overflow-tooltip="false"
        >
          <template slot-scope="scope">
            {{ scope.row[headData.prop] || '' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="view(scope.row, scope.$index)">查看</el-button>
            <el-button size="small" type="text" @click="edit(scope.row, scope.$index)">修改</el-button>
            <el-button size="small" type="text" @click="del(scope.row, scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <pagination v-bind:child-msg="pageparm" @refreshTable="refreshTable"></pagination>
    </div>
    <inputs-edit ref="editForm" @getData="getData"></inputs-edit>
  </div>
</template>
<script>
  import pagination from '@/components/pagination.vue'
  import breadcrumb from '@/components/breadcrumb.vue'
  import inputsEdit from './inputsEdit.vue'
  import * as XLSX from "xlsx"
  import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
  export default {
    name: 'inputsList',
    components: {
      pagination,
      breadcrumb,
      inputsEdit
    },
    data() {
      let title = '投入品购买管理'
      return {
        timeRange: '',
        loadtext: '加载中',
        inputsType: [{label: '全部', value: ''}].concat(CONSTPARAMS.inputsTypes),
        breadStaticData:['生产档案管理', title],
        searchForm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          bodyId: utils.getBodyId(),
          type: '',
          inputsName: '',
          storeName: '',
          startTime: '',
          endTime: ''
        },
        urlName: 'pssfindallinputs',
        //投入品类别、投入品名称、销售商、购买数量、单位、重量、单位、采购日期、生产厂家、购买人、操作（修改、删除、查看）
        tableHead: [{
          prop: 'typeName',
          label: '投入品类别',
          width: 100
        },{
          prop: 'inputsName',
          label: '投入品名称'
        },{
          prop: 'storeName',
          label: '销售商'
        },{
          prop: 'buyNum',
          label: '购买数量',
          width: 120
        },{
          prop: 'buyWeight',
          label: '购买重量',
          width: 120
        },{
          prop: 'buyDate',
          label: '采购日期',
          width: 100
        },{
          prop: 'manufactureFactory',
          label: '生产厂家'
        },{
          prop: 'buyer',
          label: '购买人'
        }],
        initForm: {
          batchId: '',
          tableName: '',
          url: [],
          bodyId: utils.getBodyId(),
          bodyName: utils.getBodyName(),
          type: '',
          registerCode: '',
          inputsName: '',
          registerBatch: '',
          manufactureFactory: '',
          buyer: '',
          specifications: '',
          storeName: '',
          storeId: '',
          buyNum: '',
          buyNumUnit: '盒',
          buyWeight: '',
          buyWeightUnit: '公斤',
          buyDate: '',
          buyBill: '',
          composition: '',
          object: '',
          productionDate: '',
          expiration: '',
          quarantinePeriod: '',
          nutrient: '',
          manureType: '',
          auditor: ''
        },
        pageName: title,
        tableName: `${title}列表`,
        linkData:[],
        thirdStaticData:[],
        loading: false,
        tableHeight: null,
        listData: [],
        pageparm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          total: 0
        }
      }
    },
    created() {
      this.initTable()
    },
    mounted(){
      this.getTopHeight()
    },
    methods: {
      download(){
        let arr = this.listData,
        tableData = [['序号', '投入品类别', '投入品名称', '销售商', '购买数量', "购买重量", "采购日期",  "生产厂家",  "购买人"]] // 表格表头
        if(!arr || !arr.length){
          this.message('暂无数据，无法导出！', "warning")
          return
        }
        arr.forEach((item, index) => {
          let rowData = []
          rowData = [
            index + 1,
            item.typeName,
            item.inputsName,
            item.storeName,
            item.buyNum,
            item.buyWeight,
            item.buyDate,
            item.manufactureFactory,
            item.buyer
          ]
          tableData.push(rowData)
        })
        this.loading = true
        this.loadtext = '导出中'
        let workSheet = XLSX.utils.aoa_to_sheet(tableData);
        let bookNew = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(bookNew, workSheet, '数据') // 工作簿名称
        let name = this.pageName + ' ' + utils.dateFormat(new Date(), "yyyy-MM-dd HH时mm分ss秒") + '.xlsx'
        XLSX.writeFile(bookNew, name) 
        this.loadtext = '加载中'
        this.loading = false
      },
      timeChange(val){
        let startTime = '',
          endTime = ''
        if(val){
          startTime = utils.dateFormat(val[0])
          endTime = utils.dateFormat(val[1])
        }
        this.searchForm.startTime = startTime
        this.searchForm.endTime = endTime
      },
      async initTable(){
        this.getData()
      },
      async add(){
        let id = await utils.getNewId(),
          obj = {
          ...this.initForm,
          batchId: id
        }
        this.$refs.editForm.initData(`新增${this.pageName}`, 'add', obj)
      },
      view(data, index){
        this.getInfo('look', data, index)
      },
      edit(data, index){
        this.getInfo('edit', data, index)
      },
      getInfo(type, data){
        let _this = this
        request.findinput({
          batchId: data.batchId
        }).then(res => {
          if(res.code == 200){
            let result = res.data
            if(!result){
              _this.message('此条数据信息获取为空！', "error")
              return
            }
            result = {
              ...result,
              url: result.url ? result.url.split(',') : [],
              buyDate: result.buyDate ? utils.dateFormat(result.buyDate, 'yyyy-MM-dd') : '',
              expiration: result.expiration ? utils.dateFormat(result.expiration, 'yyyy-MM-dd') : '',
              productionDate: result.productionDate ? utils.dateFormat(result.productionDate, 'yyyy-MM-dd') : ''
            }
            delete result.createTime
            this.$refs.editForm.initData(`${type == 'edit' ? '修改' : '查看'}${this.pageName}`, type, result)
          } else {
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
        })
      },
      del(data){
        let _this = this
        _this.$confirm(`确认要删除${data.inputsName || ''}这条记录吗？`, "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
        .then(() => {
          request.psdeleteinput({
            batchId: data.batchId
          }).then(res => {
            if(res.code == 200){
              _this.getData()
              _this.message('删除成功！', "success");
            }else{
              _this.message(res.msg || '', "error");
            }
          }).catch(e => {
            console.log(e)
          })
        }).catch(() => {})
      },
      getTopHeight(){
        let getTopHeight = utils.getTopHeight()
        this.tableHeight = `calc(100% - ${getTopHeight}px)`
      },
      refreshTable(parm) {
        this.searchForm.page = parm.page
        this.searchForm.limit = parm.limit
        this.getData()
      },
      getData(flag = false){
        let _this = this
        if(flag){
          this.searchForm.page = CONSTPARAMS.page
          this.searchForm.limit = CONSTPARAMS.limit
        }
        _this.loading = true
        request[_this.urlName](_this.searchForm).then(res => {
          _this.loading = false
          if(res.code == 200){
            let lists = res.data.list || []
            lists = lists.map(item => {
              return {
                ...item,
                buyDate: utils.dateFormat(item.buyDate, 'yyyy-MM-dd'),
                typeName: utils.getConstToVal('inputsTypes', item.type),
                buyNum: (item.buyNum || '') + (item.buyNumUnit || ''),
                buyWeight: (item.buyWeight || '') + (item.buyWeightUnit || '')
              }
            })
            _this.listData = lists
            _this.pageparm.page = _this.searchForm.page
            _this.pageparm.total = res.data.total || 0
            _this.$nextTick(() => {
              _this.$refs.table && _this.$refs.table.doLayout()
            })
          }else{
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
          _this.loading = false
        })
      },

    },
  }
</script>
<style lang="scss" scoped>
</style>